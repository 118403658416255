<template>
  <div>
    <h1>Detail Student</h1>
    <a-row :gutter="[32, 16]">
      <a-col :md="24" :lg="14">
        <div class="card-full">
          <div class="detail">
            <a-menu class="mt-3" v-model="current" mode="horizontal">
              <a-menu-item key="user">STUDENT INFORMATION</a-menu-item>
              <a-menu-item key="editProfile">EDIT PROFILE</a-menu-item>
              <a-menu-item key="editPayment">EDIT PAYMENT</a-menu-item>
            </a-menu>

            <div v-if="current[0] === 'user'" class="mt-5">
              <a-row :gutter="[32, 32]" class="ml-2">
                <a-col :sm="12" :md="8">
                  <h3 class="type">Nama</h3>
                  <p class="value">{{ student.nama ? student.nama : "-" }}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">NIS</h3>
                  <p class="value">{{ student.nis ? student.nis : "-" }}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Jenis Kelamin</h3>
                  <p class="value">
                    {{ student.jenis_kelamin ? student.jenis_kelamin : "-" }}
                  </p>
                </a-col>
              </a-row>
              <a-row :gutter="[32, 32]" class="ml-2 mt-4">
                <a-col :sm="12" :md="8">
                  <h3 class="type">Kelas</h3>
                  <p class="value">
                    {{ classOfStudent ? classOfStudent : "-" }}
                  </p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Email</h3>
                  <p class="value">{{ student.email ? student.email : "-" }}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Alamat</h3>
                  <p class="value">
                    {{ student.alamat ? student.alamat : "-" }}
                  </p>
                </a-col>
              </a-row>
            </div>
            <div v-else-if="current[0] === 'editProfile'" class="mt-4">
              <a-form @submit.prevent="updateProfile">
                <a-form-item label="NIS">
                  <a-input v-model="student.nis" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Nama">
                  <a-input v-model="student.nama" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Jenis Kelamin">
                  <a-radio-group v-model="student.jenis_kelamin">
                    <a-radio value="Laki-Laki">Laki-Laki</a-radio>
                    <a-radio value="Perempuan">Perempuan</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="Email">
                  <a-input v-model="student.email" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Alamat">
                  <a-input
                    v-model="student.alamat"
                    type="textarea"
                    style="height: 48px"
                  />
                </a-form-item>
                <a-button
                  class="btnChange"
                  type="primary"
                  html-type="submit"
                  size="large"
                  :loading="loadingUpdateProfile"
                  block
                  >Update Now</a-button
                >
              </a-form>
            </div>
            <div v-else-if="current[0] === 'editPayment'" class="mt-4">
              <a-form-item label="Payment Status">
                <a-radio-group v-model="paymentStatus" @change="changeStatus">
                  <a-radio value="paid">Paid</a-radio>
                  <a-radio value="unpaid">Unpaid</a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :md="24" :lg="10">
        <div class="card-full">
          <div class="detail">
            <div class="cui__utils__heading mb-0">
              <strong>Change Password</strong>
            </div>
            <div class="text-muted mb-3">
              You can change student password here
            </div>
            <hr />
            <a-form @submit.prevent="updatePassword">
              <a-form-item label="New Password">
                <a-input
                  v-model="newPassword"
                  type="password"
                  autocomplete="new-password"
                  style="height: 48px"
                />
              </a-form-item>
              <a-form-item label="Verify New Password">
                <a-input
                  v-model="newVerifyPassword"
                  type="password"
                  autocomplete="verify-password"
                  style="height: 48px"
                />
              </a-form-item>
              <a-button
                class="btnChange"
                type="primary"
                html-type="submit"
                size="large"
                block
                :loading="loadingUpdatePassword"
                >Update Password</a-button
              >
            </a-form>
          </div>
        </div>
        <div class="card-full">
          <div class="detail">
            <div class="cui__utils__heading mb-0">
              <strong>Delete this Account</strong>
            </div>
            <div class="text-muted mb-3">
              You can delete student account here
            </div>
            <hr />
            <a-button
              class="btnDelete"
              type="danger"
              html-type="submit"
              size="large"
              block
              :loading="loadingDelete"
              @click.prevent="deleteStudent"
              >Delete Now</a-button
            >
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: ['user'],
      student: {},
      classOfStudent: '',
      loadingUpdateProfile: false,
      loadingUpdatePassword: false,
      loadingDelete: false,
      newPassword: '',
      newVerifyPassword: '',
      paymentStatus: 'paid',
    }
  },
  methods: {
    changeStatus(e) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to change {this.student.nama}'s payment status?</div>
        ),
        onOk: () => {
          this.paymentStatus = e.target.value
          this.$store.dispatch('admin/EDIT_PAYMENT_STATUS', {
            status: this.paymentStatus,
            idMurid: this.$route.params.id,
          })
            .then(_ => {
              this.$notification.success({
                message: 'Success',
                description:
                  `${this.student.nama} payment status has been updated`,
              })
            })
        },
        onCancel: () => {
          this.paymentStatus = this.paymentStatus === 'paid' ? 'unpaid' : 'paid'
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    updateProfile() {
      this.loadingUpdateProfile = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to update {this.student.nama} profile's ?</div>
        ),
        onOk: () => {
          this.loadingUpdateProfile = false
          this.$store.dispatch('admin/UPDATE_STUDENT', { data: this.student })
            .then(res => {
              if (res === 200) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.student.nama} profile's has been updated`,
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.student.nama} profile's has not been updated`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingUpdateProfile = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update',
      })
    },
    updatePassword() {
      this.loadingUpdatePassword = true
      if (this.newPassword && this.newVerifyPassword) {
        if (this.newPassword.length >= 6 && this.newVerifyPassword.length >= 6) {
          if (this.newPassword === this.newVerifyPassword) {
            this.$confirm({
              title: 'Warning',
              content: (
                <div>Are you sure to change {this.student.nama} password ?</div>
              ),
              onOk: () => {
                this.loadingUpdatePassword = false
                this.$store.dispatch('admin/UPDATE_STUDENT_PASSWORD', { id: this.student.id, data: this.newPassword })
                  .then(res => {
                    if (res === 200) {
                      this.$notification.success({
                        message: 'Success',
                        description:
                          `New password for ${this.student.nama} has been changed`,
                      })
                    } else {
                      this.$notification.error({
                        message: 'Error',
                        description:
                          'New password has not been changed',
                      })
                    }
                  })
                this.newPassword = ''
                this.newVerifyPassword = ''
              },
              onCancel: () => {
                this.loadingUpdatePassword = false
              },
              centered: true,
              icon: 'warning',
              okType: 'primary',
              okText: 'Change',
            })
          } else {
            this.loadingUpdatePassword = false
            this.$notification.error({
              message: 'Error',
              description:
                'New password should be the same',
            })
          }
        } else {
          this.loadingUpdatePassword = false
          this.$notification.error({
            message: 'Error',
            description:
              'New password too short, minimum 6 characters',
          })
        }
      } else {
        this.loadingUpdatePassword = false
        this.$notification.error({
          message: 'Error',
          description:
            'You must fill new password',
        })
      }
    },
    deleteStudent() {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete {this.student.nama} account ?</div>
        ),
        onOk: () => {
          this.loadingDelete = false
          this.$store.dispatch('admin/DELETE_STUDENT', { id: this.student.id })
            .then(res => {
              if (res === 200) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.student.nama} account has been deleted`,
                })
                this.$router.push({ name: 'Students Admin' })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.student.nama} account has not been deleted`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
  },
  created() {
    const id = this.$route.params.id
    this.$store.dispatch('admin/FETCH_STUDENTS_BY_ID', { id })
      .then(data => {
        this.student = data
        this.classOfStudent = `${data.kela.tingkat}-${data.kela.simbol}`
        this.paymentStatus = data.status_spp
      })
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}

.detail .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}
</style>
